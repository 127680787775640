import React, { useMemo, useState } from 'react'
import { AutoColumn } from '../Column'
import { RowBetween } from '../Row'
import styled from 'styled-components'
import { TYPE, StyledInternalLink } from '../../theme'
import DoubleCurrencyLogo from '../DoubleLogo'
import { ChainId, ETHER, JSBI, TokenAmount } from 'etcmc-v2-sdk'
import { ButtonPrimary } from '../Button'
import { StakingInfo } from '../../state/stake/hooks'
import { useColor } from '../../hooks/useColor'
import { currencyId } from '../../utils/currencyId'
import { Break, CardNoise, CardBGImage } from './styled'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { useTotalSupply } from '../../data/TotalSupply'
import { usePair, usePairs } from '../../data/Reserves'
import useUSDCPrice from '../../utils/useUSDCPrice'
import { Countdown } from './../../pages/Earn/Countdown'
import { getNetworkLibrary } from '../../connectors'
import { Token, Fetcher, Route, Trade, TradeType } from 'etcmc-v2-sdk'
import { toV2LiquidityToken, useTrackedTokenPairs } from '../../state/user/hooks'
import { useTokenBalance, useTokenBalancesWithLoadingIndicator } from '../../state/wallet/hooks'

const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
 
`};
`

const Wrapper = styled(AutoColumn)<{ showBackground: boolean; bgColor: any }>`
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  position: relative;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '1')};
  background: ${({ theme, bgColor, showBackground }) =>
    `radial-gradient(91.85% 100% at 1.84% 0%, ${bgColor} 0%, ${showBackground ? theme.black : theme.bg5} 100%) `};
  color: ${({ theme, showBackground }) => (showBackground ? theme.white : theme.text1)} !important;

  ${({ showBackground }) =>
    showBackground &&
    `  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);`}
`

const TopSection = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr 120px;
  grid-gap: 0px;
  align-items: center;
  padding: 1rem;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 48px 1fr 96px;
  `};
`

// const APR = styled.div`
//   display: flex;
//   justify-content: flex-end;
// `

const BottomSection = styled.div<{ showBackground: boolean }>`
  padding: 0px 16px 12px;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '0.4')};
  border-radius: 0 0 12px 12px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  z-index: 1;
`

export default function PoolCard({ stakingInfo }: { stakingInfo: StakingInfo }) {
  const token0 = stakingInfo.tokens[0]
  const token1 = stakingInfo.tokens[1]
  const currency0 = unwrappedToken(token0)
  const currency1 = unwrappedToken(token1)
  const totalStakedAmount = stakingInfo.totalStakedAmount.toSignificant(18)
  const stakedAmount = stakingInfo.stakedAmount.toSignificant(18)
  const ME = (parseFloat(stakedAmount) / parseFloat(totalStakedAmount)).toString()

  //get LP
  const trackedTokenPairs = useTrackedTokenPairs()
  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map(tokens => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  )
  const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map(tpwlt => tpwlt.liquidityToken), [
    tokenPairsWithLiquidityTokens
  ])
  const account = stakingInfo.stakingRewardAddress

  const [v2PairsBalances] = useTokenBalancesWithLoadingIndicator(account ?? undefined, liquidityTokens)
  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )
  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  const userPoolBalance = useTokenBalance(account ?? undefined, stakingInfo.totalStakedAmount.token)
  const totalPoolTokens = useTotalSupply(stakingInfo.totalStakedAmount.token)
  const [token0Deposited, token1Deposited] =
    !!v2Pairs[0]?.[1] &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          v2Pairs[0]?.[1].getLiquidityValue(v2Pairs[0]?.[1].token0, totalPoolTokens, userPoolBalance, false),
          v2Pairs[0]?.[1].getLiquidityValue(v2Pairs[0]?.[1].token1, totalPoolTokens, userPoolBalance, false)
        ]
      : [undefined, undefined]
  const token0Value = parseFloat(token0Deposited?.toSignificant(6) || '0')
  const result = (token0Value * parseFloat(ME)).toFixed(4)
  const token1Value = parseFloat(token1Deposited?.toSignificant(6) || '0')
  const result1 = (token1Value * parseFloat(ME)).toFixed(4)

  //get LP done

  const isStaking = Boolean(stakingInfo.stakedAmount.greaterThan('0'))

  // get the color of the token
  const token = currency0 === ETHER ? token1 : token0
  const WETH = currency0 === ETHER ? token0 : token1
  const backgroundColor = useColor(token)

  const totalSupplyOfStakingToken = useTotalSupply(stakingInfo.stakedAmount.token)
  const [, stakingTokenPair] = usePair(...stakingInfo.tokens)
  // let returnOverMonth: Percent = new Percent('0')
  let valueOfTotalStakedAmountInWETH: TokenAmount | undefined
  if (totalSupplyOfStakingToken && stakingTokenPair) {
    // take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
    valueOfTotalStakedAmountInWETH = new TokenAmount(
      WETH,
      JSBI.divide(
        JSBI.multiply(
          JSBI.multiply(stakingInfo.totalStakedAmount.raw, stakingTokenPair.reserveOf(WETH).raw),
          JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
        ),
        totalSupplyOfStakingToken.raw
      )
    )
  }

  // get the USD value of staked WETH
  const USDPrice = useUSDCPrice(WETH)
  const valueOfTotalStakedAmountInUSDC =
    valueOfTotalStakedAmountInWETH && USDPrice?.quote(valueOfTotalStakedAmountInWETH)

  const from = new Token(
    ChainId.ETC,
    stakingInfo.totalRewardRate.token.address,
    stakingInfo.totalRewardRate.token.decimals,
    stakingInfo.totalRewardRate.token.symbol,
    stakingInfo.totalRewardRate.token.name
  )

  const to = new Token(ChainId.ETC, token1.address, token1.decimals, token1.symbol, token1.name)

  let [APY, setAPY] = useState<string | undefined>()

  if (from.address === to.address) {
    const t = valueOfTotalStakedAmountInWETH?.toSignificant(4)
    if (typeof t !== 'undefined') {
      const r = stakingInfo.totalRewardRate?.multiply(`${60 * 60 * 24 * 365 * 1}`)?.toFixed(0)
      APY = parseFloat(((parseFloat(r) / parseFloat(t)) * 100).toFixed(4)).toLocaleString()
    }
  } else {
    const t = valueOfTotalStakedAmountInWETH?.toSignificant(4)
    if (typeof t !== 'undefined') {
      const networkLibrary = getNetworkLibrary()
      Fetcher.fetchPairData(from, to, networkLibrary).then(pair => {
        const routeFromForTo = new Route([pair], from)
        const sum = Number((1 * Math.pow(10, from.decimals)).toFixed(0))

        const tradeFromForTo = new Trade(routeFromForTo, new TokenAmount(from, JSBI.BigInt(sum)), TradeType.EXACT_INPUT)
        const rate = tradeFromForTo.executionPrice.toSignificant(6)

        const r = stakingInfo.totalRewardRate?.multiply(`${60 * 60 * 24 * 365 * 1}`)?.toFixed(0)
        if (typeof t !== 'undefined') {
          const sum1 = parseFloat(r) * parseFloat(rate)
          let apys = parseFloat(((sum1 / parseFloat(t)) * 100).toFixed(4)).toLocaleString()
          if (isNaN(parseFloat(apys))) {
            apys = '0'
          }
          setAPY(apys)
        }
      })
    }
  }

  return (
    <Wrapper showBackground={isStaking} bgColor={backgroundColor}>
      <CardBGImage desaturate />
      <CardNoise />

      <TopSection>
        <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={24} />
        <TYPE.white fontWeight={600} fontSize={24} style={{ marginLeft: '8px' }}>
          {currency0.symbol}-{currency1.symbol}
        </TYPE.white>

        <StyledInternalLink
          to={`/farm/${currencyId(currency0)}/${currencyId(currency1)}/${stakingInfo.stakingRewardAddress}`}
          style={{ width: '100%' }}
        >
          <ButtonPrimary padding="8px" borderRadius="8px">
            {isStaking ? 'Manage' : 'Deposit'}
          </ButtonPrimary>
        </StyledInternalLink>
      </TopSection>

      <StatContainer>
        {/* not sure of this code */}
        {false && (
          <RowBetween>
            <TYPE.white> Total deposited</TYPE.white>
            <TYPE.white>
              {valueOfTotalStakedAmountInUSDC
                ? `$${(valueOfTotalStakedAmountInUSDC ?? 0).toFixed(0, { groupSeparator: ',' })}`
                : `${valueOfTotalStakedAmountInWETH?.toSignificant(4, { groupSeparator: ',' }) ?? '-'} `}{' '}
              {currency1.symbol}
            </TYPE.white>
          </RowBetween>
        )}
        <RowBetween>
          <TYPE.white> Total deposited</TYPE.white>
          <TYPE.white>
            {`${valueOfTotalStakedAmountInWETH?.toSignificant(4, { groupSeparator: ',' }) ?? '-'} ETC`}
          </TYPE.white>
        </RowBetween>
        <RowBetween>
          <TYPE.white> Pool rate </TYPE.white>
          <TYPE.white>
            {stakingInfo?.active
              ? stakingInfo?.totalRewardRate?.multiply(`${60 * 60 * 24}`)?.toFixed(0, { groupSeparator: ',' }) ?? '-'
              : '0'}{' '}
            {stakingInfo.totalRewardRate.token.symbol} / Days
          </TYPE.white>
        </RowBetween>
        <RowBetween>
          <TYPE.white> APR </TYPE.white>
          <TYPE.white>{APY}% / Years</TYPE.white>
        </RowBetween>
        <RowBetween>
          <TYPE.white>
            <Countdown exactEnd={stakingInfo.periodFinish} />
          </TYPE.white>
        </RowBetween>
      </StatContainer>

      {isStaking && (
        <>
          <Break />
          <div style={{ height: '12px' }} />
          <BottomSection showBackground={true}>
            <TYPE.black color={'white'} fontWeight={500}>
              <span>Pooled {token0Deposited?.token.symbol}</span>
            </TYPE.black>
            <TYPE.black style={{ textAlign: 'right' }} color={'white'} fontWeight={500}>
              {result}
            </TYPE.black>
          </BottomSection>
          <BottomSection showBackground={true}>
            <TYPE.black color={'white'} fontWeight={500}>
              <span>Pooled {token1Deposited?.token.symbol}</span>
            </TYPE.black>
            <TYPE.black style={{ textAlign: 'right' }} color={'white'} fontWeight={500}>
              {result1}
            </TYPE.black>
          </BottomSection>
          <BottomSection showBackground={true}>
            <TYPE.black color={'white'} fontWeight={500}>
              <span>Your rate</span>
            </TYPE.black>

            <TYPE.black style={{ textAlign: 'right' }} color={'white'} fontWeight={500}>
              <span role="img" aria-label="wizard-icon" style={{ marginRight: '0.5rem' }}>
                ⚡
              </span>
              {`${stakingInfo.rewardRate?.multiply(`${60 * 60 * 24}`)?.toSignificant(4, { groupSeparator: ',' })}`}{' '}
              {stakingInfo.totalRewardRate.token.symbol} / Days
            </TYPE.black>
          </BottomSection>
        </>
      )}
    </Wrapper>
  )
}
